<template>
  <div class="order-box">
    <div class="order">
      <!-- 地图 -->
      <div
        id="container"
        :style="[2, 4].includes(detailList.orderStatus) ? 'height: 60%' : '45%'"
      >
        <!-- 定位 -->
        <div class="location">
          <img src="../assets/detail/location.png" @click="clickLoction" />
        </div>
      </div>
      <!-- 线路 -->
      <div id="panel"></div>
      <!-- 配送信息 -->
      <div class="order-info">
        <!-- status -->
        <div class="order-status">
          <div class="top">
            <p class="status">{{ detailList.orderStatusName }}</p>
            <a :href="'tel:' + detailList.serviceTel" rel="external nofollow">
              <img src="../assets/detail/customer.png" />
            </a>
          </div>
          <p class="status-text" v-if="detailList.showMessage">
            {{ detailList.showMessage }}
          </p>
          <div class="vinId" v-if="![2, 4].includes(detailList.orderStatus)">
            <img class="car-top" src="../assets/detail/car-top.png" alt="" />
            <div v-if="detailList.vinCode">
              <span style="margin-right: 0.01rem">
                {{ detailList.vinCode }}
              </span>
              <span>({{ detailList.vinId }})</span>
            </div>
            <div v-else>
              <span>{{ detailList.vinId }}</span>
            </div>
          </div>

          <!-- 按钮 -->
          <div class="bottom-btn">
            <div
              class="top-door"
              v-if="detailList.orderStatus === 14 && detailList.lockType === 2"
            >
              <p @click="openDoor('openTop')">开顶门</p>
              <p @click="openDoor('closeTop')">关顶门</p>
            </div>
            <div class="top-door open">
              <p
                @click="openDoor('open')"
                v-if="
                  (detailList.orderStatus === 10 ||
                    detailList.orderStatus === 2) &&
                  (detailList.lockType === 0 || detailList.lockType === 2) &&
                  (detailList.cabinCode === 'G' || detailList.cabinCode === 'E')
                "
              >
                开门
              </p>
            </div>
            <p
              class="start"
              @click="openDoor('start')"
              v-if="
                (detailList.orderStatus === 10 &&
                  (detailList.lockType === 0 || detailList.lockType === 2) &&
                  (detailList.cabinCode === 'G' ||
                    detailList.cabinCode === 'E')) ||
                (detailList.orderStatus === 14 && detailList.lockType === 2)
              "
            >
              发车
            </p>
          </div>
        </div>
        <!-- 取货码 -->
        <div
          class="order-code"
          v-if="
            ![2, 4].includes(detailList.orderStatus) &&
            detailList.showPickUpCode === 1
          "
        >
          <p class="p1" v-if="detailList.deliveryType === 0">开柜码</p>
          <p class="p1" v-if="detailList.deliveryType === 1">验证码</p>
          <p class="code">{{ detailList.pickUpCode || '' }}</p>
          <p class="p2" v-if="detailList.deliveryType === 0">
            {{ detailList.openDoorMessage }}
          </p>
        </div>

        <!-- 收发信息 -->
        <div class="order-user">
          <!-- 发 -->
          <div class="send" style="margin-top: 0.12rem">
            <img src="../assets/detail/consignor.png" />
            <div class="user-info">
              <div class="user-add">
                <p class="address">{{ detailList.stationName }}</p>
                <p
                  class="user-nav send-nav"
                  @click="clickNav"
                  v-if="[1, 14].includes(detailList.orderStatus)"
                >
                  导航
                </p>
              </div>
              <div class="info-text">
                <span style="margin-right: 0.2rem" v-if="detailList.userName">
                  {{ detailList.userName }}
                </span>
                <span>{{ userPhone }}</span>
                <a
                  :href="'tel:' + detailList.userPhone"
                  rel="external nofollow"
                >
                  <img src="../assets/detail/send-phone.png" />
                </a>
              </div>
            </div>
          </div>
          <!-- 收 -->
          <div class="send" style="margin-top: 0.16rem">
            <img src="../assets/detail/consignee.png" />
            <div class="user-info">
              <div class="user-add">
                <p class="address">{{ detailList.rcvStationName }}</p>
                <p
                  class="user-nav"
                  @click="clickNav"
                  v-if="![1, 14].includes(detailList.orderStatus)"
                >
                  导航
                </p>
              </div>
              <div class="info-text">
                <span style="margin-right: 0.2rem" v-if="detailList.rcvName">
                  {{ detailList.rcvName }}
                </span>
                <span>{{ rcvPhone }}</span>
                <a :href="'tel:' + detailList.rcvPhone" rel="external nofollow">
                  <img src="../assets/detail/receiving-phone.png" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- 底部详情 -->
        <div class="bottom-details">
          <div
            class="price-line"
            v-if="![2, 4, 3, 10].includes(detailList.orderStatus)"
            style="margin-bottom: 0.32rem"
          >
            <p class="p3">订单金额</p>
            <p class="p4" style="font-weight: 600">
              <span style="font-size: 0.22rem; margin-right: -0.05rem">
                ￥
              </span>
              {{ totalPrice }}
            </p>
          </div>
          <div class="price-line ordercode-line">
            <p class="p3">订单号</p>
            <p class="p4">{{ detailList.orderCode }}</p>
          </div>
          <div
            class="price-line time"
            v-if="![2, 4, 3, 10].includes(detailList.orderStatus)"
            style="margin-top: 0.32rem"
          >
            <p class="p3">下单时间</p>
            <p class="p4">{{ detailList.dateTime }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- 地图弹窗 -->
    <div :class="showModal ? 'modal-box modal-boxShow' : 'modal-box'"></div>
    <div
      :class="showModal ? 'modal-content modal-contentShow' : 'modal-content'"
    >
      <p
        class="nav-name"
        v-for="(item, index) in actions"
        :key="index"
        @click="selectNav(item.id)"
      >
        {{ item.name }}
      </p>
      <p class="line"></p>
      <p class="cancel" @click="hideModal">取消</p>
    </div>

    <!-- 开门 发车弹窗 -->
    <div
      :class="showDoorFlag ? 'open-modal open-modalShow' : 'open-modal'"
    ></div>
    <div
      :class="
        showDoorFlag
          ? 'open-modal-content open-modal-contentShow'
          : 'open-modal-content'
      "
    >
      <p class="title">{{ titleText }}</p>
      <p class="des">{{ desText }}</p>
      <div class="content-btn">
        <p class="cancel" @click="handleCancel">取消</p>
        <p class="cancel sure" @click="handleSure">确定</p>
      </div>
    </div>
  </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader';
import {
  deliveryDetail,
  vinRunStatusInfo,
  openDoor,
  getVinDoorNums,
  vehicleOpenDoor,
  orderDetailSendVin,
} from '@/api/api';
import moment from 'moment';
import { getArcMidPoint, getCenterLonLat } from '@/utils';

const userLocation =
  'https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/userLocation.png';

export default {
  name: 'orderDetailApp',
  data() {
    return {
      map: null,
      detailList: {}, // 订单详情列表
      userPhone: '',
      vinInfo: {}, // 车辆信息
      marker: null, //地图
      actions: [
        { id: 1, name: '百度地图' },
        { id: 2, name: '高德地图' },
      ],
      showModal: false,
      geolocation: null,
      firstInto: true, // 是否第一次拖拽
      AMap: null,
      vinBubbleFlag: true, // 车辆气泡显示
      riding: null, // 骑行数据
      autoZoom: 14, // 默认比列尺
      markerLocation: null, // 定位marker
      labelDistribution: null,
      labelSigned: null,
      bezierCurve: null,
      firstGetLocation: true, // 第一次进入获取位置 不确定中心点
      markerList: null, // 收发icon marker
      timer: null,
      number: 0,
      rcvPhone: '', // 收件人电话
      totalPrice: null, // 总价
      showDoorFlag: false, //开关门/发车
      titleText: '',
      desText: '',
      modalFlag: '', // 开关门/发车 flag存储
      vehicleCabinetInfo: {}, // 开关顶门查询
    };
  },

  mounted: function () {
    this.$nextTick(async () => {
      await this.getDetail();
      this.timer = setInterval(() => {
        this.getDetail();
        this.number += 1;
      }, 5000);
    });
  },
  beforeDestroy: function () {
    clearInterval(this.timer);
  },
  created() {},
  methods: {
    // 地图初始化
    initMap() {
      AMapLoader.load({
        key: 'e618efe9bc3dbbbd1ddfa9d847f49d74', // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: [
          'AMap.BezierCurve',
          'AMap.Geolocation',
          'AMap.Geocoder',
          'AMap.Riding',
          'AMap.MoveAnimation',
        ], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          this.AMap = AMap;
          this.map = new AMap.Map('container', {
            //设置地图容器id
            viewMode: '3D', //是否为3D地图模式
            zoom: 14, //初始化地图级别
            center: [116.403963, 39.915119], //初始化地图中心点位置
            resizeEnable: true, // 是否监控地图容器尺寸变化，默认值为false
            vectorMapForeign: 'Local',
            mapStyle: 'amap://styles/whitesmoke', //设置地图的显示样式
            rotateEnable: false, // 地图是否可旋转，3D视图默认为true，2D视图默认false
          });

          this.map.on('dragend', (e) => {
            this.firstInto = false;
          });

          AMap.plugin('AMap.Geolocation', () => {
            this.geolocation = new AMap.Geolocation({
              enableHighAccuracy: true, //是否使用高精度定位，默认:true
              timeout: 10000, //超过10秒后停止定位，默认：5s
              buttonPosition: 'RB', //定位按钮的停靠位置
              buttonOffset: new AMap.Pixel(10, 20), //定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
              zoomToAccuracy: true, //定位成功后是否自动调整地图视野到定位点
              // markerOptions: {
              //   offset: [-14, -14],
              //   icon: new AMap.Icon({
              //     size: [28, 28],
              //     image: userLocation,
              //     imageSize: [28, 28]
              //   })
              // },
            });
            // this.map.addControl(this.geolocation);
            this.geolocation.getCurrentPosition((status, result) => {
              if (status == 'complete') {
                this.getPersonLoc(AMap, result);
              } else {
                console.log(result, '....error');
              }
            });
          });

          // 骑行路线声明
          this.riding = new AMap.Riding({
            map: this.map,
            panel: '',
            hideMarkers: true,
            autoFitView: false,
          });
          this.scale();

          if (![2, 4].includes(this.detailList.orderStatus)) {
            this.statusLine();
            setTimeout(() => {
              this.getDetailInfo(AMap);
            }, 1000);
            let points;
            if ([3, 12].includes(this.detailList.orderStatus)) {
              // 配送中 待配送
              points = getCenterLonLat(
                this.vinInfo && this.vinInfo.lng,
                this.vinInfo && this.vinInfo.lat,
                this.detailList && this.detailList.rcvLng,
                this.detailList && this.detailList.rcvLat
              );
            } else if (this.detailList.orderStatus == 1) {
              // 揽收中
              points = getCenterLonLat(
                this.vinInfo && this.vinInfo.lng,
                this.vinInfo && this.vinInfo.lat,
                this.detailList && this.detailList.lng,
                this.detailList && this.detailList.lat
              );
            } else if (this.detailList.orderStatus == 14) {
              // 待投柜
              points = getCenterLonLat(
                this.detailList && this.detailList.lng,
                this.detailList && this.detailList.lat,
                this.detailList && this.detailList.lng,
                this.detailList && this.detailList.lat
              );
            } else {
              // 待签收
              points = getCenterLonLat(
                this.detailList && this.detailList.rcvLng,
                this.detailList && this.detailList.rcvLat,
                this.detailList && this.detailList.rcvLng,
                this.detailList && this.detailList.rcvLat
              );
            }
            this.map && this.map.setCenter([points[0], points[1]], true);
          } else {
            setTimeout(() => {
              this.getDetailInfo(AMap);
              this.bezierCurveLine();
            }, 1000);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    // 人的定位marker
    getPersonLoc(AMap, result) {
      var iconLocation = new AMap.Icon({
        size: new AMap.Size(34, 34), // 图标尺寸
        image: userLocation,
        // image:
        //   'https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/my-icon.png', // Icon的图像
        imageSize: new AMap.Size(34, 34), // 根据所设置的大小拉伸或压缩图片
      });

      this.markerLocation = new AMap.Marker({
        position: new AMap.LngLat(result.position.lng, result.position.lat),
        icon: iconLocation, // 添加 Icon 实例
        zIndex: 999,
        anchor: 'bottom-center',
        offset: new AMap.Pixel(0, 0),
      });
      this.map.add(this.markerLocation);
      !this.firstGetLocation &&
        this.map.setCenter([result.position.lng, result.position.lat]);
    },

    // 点击定位按钮 人
    clickLoction() {
      this.geolocation.getCurrentPosition((status, result) => {
        if (status == 'complete') {
          this.map.remove(this.markerLocation);
          this.firstGetLocation = false;
          this.firstInto = false;
          this.getPersonLoc(this.AMap, result);
        } else {
          console.log(result, '....error');
        }
      });
    },

    // 订单详情收货人
    getDetail() {
      deliveryDetail({
        orderCode: this.$route.query.orderCode,
        loading: false,
        subId: this.$route.query.subId,
      }).then((res) => {
        this.detailList = res;
        this.totalPrice = Number.isInteger(res.totalPrice)
          ? res.totalPrice.toFixed(1)
          : res.totalPrice.toFixed(2);

        this.userPhone = res.userPhone.replace(
          res.userPhone.substr(3, 4),
          '****'
        );
        this.rcvPhone = res.rcvPhone.replace(res.rcvPhone.substr(3, 4), '****');
        if (this.number == 0) {
          this.initMap();
          if ([3, 10, 1, 14, 12].includes(this.detailList.orderStatus)) {
            this.getVinInfo();
          }
        } else {
          if ([2, 4].includes(this.detailList.orderStatus)) {
            this.riding == null ? '' : this.riding.clear();
            this.marker == null ? '' : this.map.remove(this.marker);
            this.markerList == null ? '' : this.map.remove(this.markerList);
            this.bezierCurve == null ? '' : this.map.remove(this.bezierCurve);
            this.getDetailInfo(this.AMap);
            this.bezierCurveLine();
          } else if ([3, 10, 1, 14, 12].includes(this.detailList.orderStatus)) {
            this.bezierCurve == null ? '' : this.map.remove(this.bezierCurve);
            this.markerList == null ? '' : this.map.remove(this.markerList);
            this.getDetailInfo(this.AMap);
            this.getVinInfo();
          }
        }

        console.log(this.number, '....number');
      });
    },

    // 收发icon
    getDetailInfo(AMap) {
      console.log(AMap, '....AMap');
      var iconReceiving = new AMap.Icon({
        size: new AMap.Size(38, 38), // 图标尺寸
        image:
          'https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/receiving-icon.png', // Icon的图像
        imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
        imageSize: new AMap.Size(38, 38), // 根据所设置的大小拉伸或压缩图片
      });

      var iconSend = new AMap.Icon({
        size: new AMap.Size(38, 38), // 图标尺寸
        image:
          'https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/send-icon.png', // Icon的图像
        imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
        imageSize: new AMap.Size(38, 38), // 根据所设置的大小拉伸或压缩图片
      });

      // 发件人
      var markerSend = new AMap.Marker({
        position: new AMap.LngLat(this.detailList.lng, this.detailList.lat),
        icon: iconSend, // 添加 Icon 实例
        anchor: 'bottom-center',
        offset: new AMap.Pixel(0, 0),
      });
      // 收件人
      var markerReceiving = new AMap.Marker({
        position: new AMap.LngLat(
          this.detailList.rcvLng,
          this.detailList.rcvLat
        ),
        icon: iconReceiving, // 添加 Icon 实例
        anchor: 'bottom-center',
        offset: new AMap.Pixel(0, 0),
      });
      this.markerList = [
        [1, 14, 2, 4].includes(this.detailList.orderStatus) && markerSend,
        [12, 3, 10, 2, 4].includes(this.detailList.orderStatus) &&
          markerReceiving,
      ];
      this.map.add(this.markerList);
    },

    // 贝塞尔曲线 已完成和已取消路线展示
    bezierCurveLine() {
      const points = getArcMidPoint(
        this.detailList.lng,
        this.detailList.rcvLng,
        this.detailList.lat,
        this.detailList.rcvLat
      );

      var path = [
        [this.detailList.lng, this.detailList.lat],

        [points[0], points[1], this.detailList.rcvLng, this.detailList.rcvLat],
      ];

      this.bezierCurve = new AMap.BezierCurve({
        path: path,
        isOutline: true,
        outlineColor: '#ffeeff',
        borderWeight: 3,
        strokeColor: '#436EFF',
        strokeOpacity: 1,
        strokeWeight: 6,
        // 线样式还支持 'dashed'
        strokeStyle: 'solid',
        // strokeStyle是dashed时有效
        strokeDasharray: [10, 10],
        lineJoin: 'round',
        lineCap: 'round',
        zIndex: 50,
      });
      this.firstInto &&
        this.map &&
        this.map.setCenter([points[0], points[1]], true);

      this.map.add(this.bezierCurve);
    },

    // 车辆信息
    getVinInfo() {
      vinRunStatusInfo({
        vin: this.detailList.vehicleVin,
      }).then((res) => {
        this.vinInfo = res;
        this.marker && this.marker.setPosition([res.lng, res.lat]);
        this.statusLine();
      });
    },

    // 条件判断展示i线路
    statusLine() {
      if (this.detailList.orderStatus === 1) {
        // 揽收中
        this.collectingLine();
      } else if ([3, 12].includes(this.detailList.orderStatus)) {
        // 配送中 待配送
        this.deliveryLine();
      } else if (this.detailList.orderStatus === 10) {
        // 待签收
        this.toBeSignedLine();
      } else if (this.detailList.orderStatus === 14) {
        // 待投柜
        this.cabinetPutLine();
      }
    },

    // 揽收中展示路线
    collectingLine() {
      console.log(this.riding, '..this.riding');

      this.riding &&
        this.riding.search(
          [this.vinInfo.lng, this.vinInfo.lat],
          [this.detailList.lng, this.detailList.lat],
          (status, result) => {
            if (status === 'complete') {
              this.vinBubbleFlag && this.vinBubble(result.routes);
              console.log('绘制成功');
            } else {
              console.log('绘制失败');
            }
          }
        );
    },

    // 配送中路线展示
    deliveryLine() {
      this.riding &&
        this.riding.search(
          [this.vinInfo.lng, this.vinInfo.lat],
          [this.detailList.rcvLng, this.detailList.rcvLat],
          (status, result) => {
            if (status === 'complete') {
              this.vinBubbleFlag && this.vinBubble(result.routes);
              console.log('绘制成功');
            } else {
              console.log('绘制失败');
            }
          }
        );
    },

    // 待签收路线展示
    toBeSignedLine() {
      this.riding &&
        this.riding.search(
          [this.detailList.rcvLng, this.detailList.rcvLat],
          [this.detailList.rcvLng, this.detailList.rcvLat],
          (status, result) => {
            if (status === 'complete') {
              this.vinBubbleFlag && this.vinBubble(result.routes);
              console.log('绘制成功--车辆信息');
            } else {
              console.log('绘制失败');
            }
          }
        );
    },

    // 待投柜路线展示
    cabinetPutLine() {
      this.riding &&
        this.riding.search(
          [this.detailList.lng, this.detailList.lat],
          [this.detailList.lng, this.detailList.lat],
          (status, result) => {
            if (status === 'complete') {
              this.vinBubbleFlag && this.vinBubble(result.routes);
              console.log('绘制成功--车辆信息');
            } else {
              console.log('绘制失败');
            }
          }
        );
    },

    //车辆气泡显示
    vinBubble(result) {
      var time = moment().add(result[0].time, 's').format('HH:mm');

      this.marker == null ? '' : this.map.remove(this.marker);
      //  上门揽收中
      this.labelCollecting = `
          <div class='labelContent'>
            <p>距离发货点<span style="color: #436EFF;">
              ${
                result[0].distance < 1000
                  ? result[0].distance
                  : (result[0].distance / 1000).toFixed(1)
              }
              ${result[0].distance < 1000 ? 'm' : 'km'}
            </span></p>
            <p>预计<span style="color: #436EFF;"> ${time || '-'} </span>到达</p>
          </div>
        `;
      // 待投柜
      this.labelCabinetPut = `
          <div class='labelContent'>
            <p>已于<span style="color: #436EFF;"> ${
              this.detailList.dateUpd || '-'
            } </span>抵达发货点</p>
          </div>
        `;

      //  待配送 配送中
      this.labelDistribution = `
          <div class='labelContent'>
            <p>距离收货点<span style="color: #436EFF;">
              ${
                result[0].distance < 1000
                  ? result[0].distance
                  : (result[0].distance / 1000).toFixed(1)
              }
              ${result[0].distance < 1000 ? 'm' : 'km'}
            </span></p>
            <p>预计<span style="color: #436EFF;"> ${time || '-'} </span>到达</p>
          </div>
        `;

      // 待签收
      this.labelSigned = `
          <div class='labelContent'>
            <p>已于<span style="color: #436EFF;"> ${
              this.detailList.dateUpd || '-'
            } </span>抵达收货点</p>
          </div>
        `;

      var icon = new AMap.Icon({
        size: new AMap.Size(40, 38), // 图标尺寸
        image:
          'https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/car.png', // Icon的图像
        imageOffset: new AMap.Pixel(0, 0), // 图像相对展示区域的偏移量，适于雪碧图等
        imageSize: new AMap.Size(40, 38), // 根据所设置的大小拉伸或压缩图片
      });

      let labelContent =
        this.detailList.orderStatus === 1
          ? this.labelCollecting
          : this.detailList.orderStatus === 14
          ? this.labelCabinetPut
          : this.detailList.orderStatus === 10
          ? this.labelSigned
          : this.labelDistribution;
      this.marker = new AMap.Marker({
        position: new AMap.LngLat(this.vinInfo.lng, this.vinInfo.lat),
        // offset: new AMap.Pixel(-20, -38),
        icon: icon, // 添加 Icon 实例
        label: {
          content: labelContent,
          offset: new AMap.Pixel(-75, -70),
        },
        anchor: 'bottom-center',
        offset: new AMap.Pixel(0, 0),
      });

      this.map.add(this.marker);
      this.vinBubbleFlag = true;
    },

    // 计算比例尺
    scale() {
      var statusTwoFour = AMap.GeometryUtil.distance(
        [this.detailList.lng, this.detailList.lat],
        [this.detailList.rcvLng, this.detailList.rcvLat]
      ); // 状态为2，4的时候
      var statueOther = AMap.GeometryUtil.distance(
        [this.vinInfo.lng, this.vinInfo.lat],
        [this.detailList.rcvLng, this.detailList.rcvLat]
      ); // 状态不为 2，4 的时候

      var distance = [2, 4].includes(this.detailList.orderStatus)
        ? statusTwoFour
        : statueOther;

      if (distance >= 650000) {
        this.autoZoom = 4;
      } else if (distance >= 200000) {
        this.autoZoom = 7;
      } else if (distance >= 50000) {
        this.autoZoom = 9;
      } else if (distance >= 7500) {
        this.autoZoom = 11;
      } else if (distance >= 4000) {
        this.autoZoom = 12;
      } else if (distance >= 1700) {
        this.autoZoom = 13;
      } else if (distance >= 500) {
        this.autoZoom = 14;
      } else if (distance < 500) {
        this.autoZoom = 18;
      }
      this.map.setZoom(this.autoZoom, true); //设置地图层级
    },

    // 显示弹窗
    clickNav() {
      this.showModal = true;
    },

    // 隐藏弹窗
    hideModal() {
      this.showModal = false;
    },

    // 点击调起导航
    selectNav(id) {
      let longitude = this.detailList.rcvLng, //"导航地址经度",
        latitude = this.detailList.rcvLat; // "导航地址维度"
      let name = this.detailList.rcvStationName; //"导航地址名称";
      let url = '';
      const u = navigator.userAgent;
      const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
      const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1;
      if (isAndroid) {
        switch (id) {
          //下面是拼接url,不同系统以及不同地图都有不同的拼接字段
          case 1:
            url = `baidumap://map/marker?location=${latitude},${longitude}&title=${name}&coord_type=gcj02&src=andr.baidu.openAPIdemo`;
            break;
          case 2:
            url = `androidamap://viewMap?sourceApplication=appname&poiname=${name}&lat=${latitude}&lon=${longitude}&dev=0`;
            break;
          default:
            break;
        }
        if (url != '') {
          window.open(url, '_blank');
        }
      } else if (isiOS) {
        switch (id) {
          case 1:
            url = `baidumap://map/marker?location=${latitude},${longitude}&title=${name}&content=${name}&src=ios.baidu.openAPIdemo&coord_type=gcj02`;
            break;
          case 2:
            url = `iosamap://viewMap?sourceApplication=applicationName&poiname=${name}&lat=${latitude}&lon=${longitude}&dev=0`;
            break;
          default:
            break;
        }
        if (url != '') {
          window.open(url, '_blank');
        }
      }
    },

    // 开关门/发车弹窗
    openDoor(flag) {
      this.showDoorFlag = true;
      this.modalFlag = flag;
      if (flag === 'openTop') {
        this.titleText = '提示';
        this.desText = '确定开启顶门吗？';
      } else if (flag === 'closeTop') {
        this.titleText = '提示';
        this.desText = '确定关闭顶门吗？';
      } else if (flag === 'open') {
        this.titleText = '提示';
        this.desText = '确定开启柜门吗？';
      } else if (flag === 'start') {
        this.titleText = '确认发车';
        this.desText = '确认后，车辆将前往下一个目的地';
      }
    },

    // 取消开门关门/发车
    handleCancel() {
      this.showDoorFlag = false;
    },

    // 点击开关门/发车确定
    handleSure() {
      // 开顶门openTop  关顶门closeTop   open开门 start发车
      if (this.modalFlag === 'openTop') {
        this.openCloseTopDoor(1);
      } else if (this.modalFlag === 'closeTop') {
        this.openCloseTopDoor(0);
      } else if (this.modalFlag === 'open') {
        this.openDoorStart();
      } else if (this.modalFlag === 'start') {
        this.orderSendVin();
      }
    },

    // 打开柜门
    openDoorStart() {
      let { orderCode, vehicleVin } = this.detailList;
      openDoor({
        orderCode: orderCode,
        vin: vehicleVin,
      }).then((res) => {
        console.log(res, '..res');
        this.$toast(
          '开门成功',
          { duration: 2000 },
          'https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/toast-suc.png'
        );
        this.handleCancel();
      });
    },

    // 开关顶门点击
    openCloseTopDoor(control) {
      let { vehicleVin } = this.detailList;
      getVinDoorNums({
        vin: vehicleVin,
      }).then((res) => {
        this.vehicleCabinetInfo = res;
        if (res.smartCabinetInfos.length > 0) {
          let every = res.smartCabinetInfos.some((item) => item.side === 4);
          if (every) {
            if (res.smartCabinetInfos[0].side === 1) {
              // 具有顶门和侧门，且侧门side==1， 顶门side == 4
              // 侧门取magneticState表示1:开着门
              if (res.smartCabinetInfos[0].magneticState === 1) {
                this.$toast(
                  '侧门打开时不允许操作，关闭侧门后重试',
                  { duration: 2000 },
                  'https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/toast-fail.png'
                );
                this.handleCancel();
              }
              // 调开门 control 0关 1开
              this.vehicleOpenDoors(3, 3, control);
            }
          } else {
            this.$toast(
              '当前车辆非顶开门货箱',
              { duration: 2000 },
              'https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/toast-fail.png'
            );
            this.handleCancel();
          }
        } else {
          this.$toast(
            '当前车辆非顶开门货箱',
            { duration: 2000 },
            'https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/toast-fail.png'
          );
          this.handleCancel();
        }
      });
    },

    vehicleOpenDoors(side, lockId, control) {
      let { vehicleVin } = this.detailList;
      vehicleOpenDoor({
        vin: vehicleVin,
        side: [side],
        lockIds: [lockId],
        cabinCode: 'G',
        control: control,
      }).then((res) => {
        console.log(res, '..res');
        this.$toast(
          control === 1 ? '开锁指令已下发' : '关锁指令已下发',
          { duration: 2000 },
          'https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/toast-suc.png'
        );
        this.handleCancel();
      });
    },

    // 发车
    orderSendVin() {
      let { orderCode } = this.detailList;
      orderDetailSendVin({
        orderCode: orderCode,
      }).then((res) => {
        console.log(res, '..res');
        this.$toast(
          '任务已下发',
          { duration: 2000 },
          'https://cybertron-public.neolix.net/NeolixData/Default/frontend/pcs/app/toast-suc.png'
        );
        this.handleCancel();
      });
    },
  },
};
</script>

<style scoped lang="scss">
.order-box {
  width: 100%;
  height: 100%;
  background: #f6f7f8;
}

.order {
  width: 100%;
  height: 100%;

  #container {
    position: relative;
    padding: 0rem;
    margin: 0rem;
    width: 100%;
    height: 45%;

    .location {
      width: 1rem;
      height: 1rem;
      position: absolute;
      right: 0.12rem;
      bottom: 0.12rem;
      z-index: 9;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .order-info {
    width: 100%;
    // height: 55%;
    background: #f6f7f8;
    box-shadow: 0 -0.02rem 0.16rem 0 rgba(0, 0, 0, 0.1);
    border-radius: 0.32rem 0.32rem 0 0;
    padding: 0.2rem;
    box-sizing: border-box;

    .order-status {
      width: 100%;
      // height: 2rem;
      background: #ffffff;
      box-shadow: 0 0 0.12rem 0 rgba(0, 0, 0, 0.04);
      border-radius: 0.24rem;
      padding: 0.24rem 0.28rem;
      box-sizing: border-box;

      .top {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .status {
          font-size: 0.4rem;
          color: #222222;
          font-weight: 600;
        }

        a {
          width: 0.56rem;
          height: 0.56rem;
          display: flex;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      .status-text {
        font-size: 0.28rem;
        color: #9798ab;
        font-weight: 400;
        margin-top: 0.04rem;
      }

      .vinId {
        display: flex;
        align-items: center;
        font-size: 0.28rem;
        color: #000000;
        font-weight: 600;
        .car-top {
          width: 0.76rem;
          height: 0.76rem;
        }
      }

      .bottom-btn {
        font-size: 0.26rem;
        font-weight: 500;
        color: rgba(67, 110, 255, 1);
        vertical-align: top;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .top-door {
          display: flex;
          p {
            padding: 0.12rem 0.27rem;
            border-radius: 0.32rem;
            background: rgba(255, 255, 255, 1);
            border: 2px solid rgba(67, 110, 255, 1);
            margin-right: 0.12rem;
          }
        }
        .start {
          color: rgba(255, 255, 255, 1);
          padding: 0.13rem 0.4rem;
          border-radius: 0.32rem;
          background: rgba(67, 110, 255, 1);
        }
      }
    }

    .order-code {
      width: 100%;
      // height: 2.52rem;
      background: #ffffff;
      box-shadow: 0 0 0.12rem 0 rgba(0, 0, 0, 0.04);
      border-radius: 0.24rem;
      padding: 0.28rem 0;
      box-sizing: border-box;
      margin: 0.2rem 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .p1 {
        font-size: 0.32rem;
        color: #000000;
        font-weight: 600;
        text-align: center;
      }

      .code {
        font-size: 0.64rem;
        color: #fc7e36;
        text-align: center;
        font-weight: 600;
        margin-top: 0.2rem;
      }

      .p2 {
        font-size: 0.28rem;
        color: #9798ab;
        text-align: center;
        font-weight: 400;
        margin-top: 0.2rem;
      }
    }

    .order-user {
      width: 100%;
      // height: 2.4rem;
      background: #ffffff;
      box-shadow: 0rem 0rem 0.12rem 0rem rgba(0, 0, 0, 0.04);
      border-radius: 0.24rem;
      padding: 0.24rem 0.28rem;
      box-sizing: border-box;
      margin-top: 0.2rem;

      .send {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        img {
          width: 0.36rem;
          height: 0.36rem;
          margin-right: 0.2rem;
        }

        .user-info {
          width: 100%;

          .user-add {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }

          .address {
            width: 5rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 0.32rem;
            color: #000000;
            font-weight: 600;
          }

          .user-nav {
            font-size: 0.24rem;
            color: #00cb95;
            font-weight: 500;
            padding: 0.02rem 0.12rem;
            box-sizing: border-box;
            border: 1px solid rgba(0, 203, 149, 1);
            border-radius: 0.5rem;
          }

          .send-nav {
            color: #436eff;
            border: 1px solid #436eff;
          }

          .info-text {
            font-size: 0.28rem;
            color: #9798ab;
            font-weight: 400;
            margin-top: -0.1rem;
            display: flex;
            align-items: center;

            img {
              width: 0.4rem;
              height: 0.4rem;
              margin-left: 0.12rem;
              margin-top: 0.1rem;
            }
          }
        }
      }
    }

    .bottom-details {
      width: 100%;
      background: #ffffff;
      box-shadow: 0rem 0rem 0.12rem 0rem rgba(0, 0, 0, 0.04);
      border-radius: 0.24rem;
      padding: 0.24rem 0.28rem;
      box-sizing: border-box;
      margin-top: 0.2rem;

      .price-line {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .p3,
      .p4 {
        font-size: 0.28rem;
        color: #9798ab;
        font-weight: 400;
      }

      .p4 {
        color: #000000;
      }
    }
  }
}

.modal-boxShow,
.open-modalShow {
  opacity: 1 !important;
  visibility: visible !important;
}

.modal-contentShow,
.open-modal-contentShow {
  transform: translateY(0) !important;
}

.modal-box,
.open-modal {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s;
  z-index: 10;
}

.modal-content {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 3.59rem;
  background: #ffffff;
  border-radius: 0.32rem 0.32rem 0px 0px;
  overflow: hidden;
  transform: translateY(100%);
  transition: transform 0.3s;
  z-index: 11;

  .nav-name {
    width: 100%;
    height: 1.2rem;
    font-size: 0.34rem;
    color: #000000;
    text-align: center;
    border-bottom: solid 1px #e0e0e0;
    line-height: 1.2rem;
  }

  .nav-name:nth-child(2) {
    border-bottom: none;
  }

  .line {
    width: 100%;
    height: 0.14rem;
    background: #f7f7f7;
  }

  .cancel {
    width: 100%;
    height: 1.12rem;
    font-size: 0.34rem;
    color: #9798ab;
    text-align: center;
    line-height: 1.12rem;
  }
}

.open-modal-content {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  border-radius: 0.32rem 0.32rem 0px 0px;
  background: rgba(255, 255, 255, 1);
  overflow: hidden;
  transform: translateY(100%);
  transition: transform 0.3s;
  z-index: 11;
  .title {
    font-size: 0.4rem;
    font-weight: 600;
    color: rgba(34, 34, 34, 1);
    text-align: center;
    padding-top: 0.48rem;
  }
  .des {
    font-size: 0.32rem;
    font-weight: 400;
    color: rgba(34, 34, 34, 1);
    text-align: center;
    padding: 0.36rem 0.47rem;
  }
  .content-btn {
    display: flex;
    justify-content: space-evenly;
    padding-bottom: 0.4rem;
    .cancel {
      padding: 0.2rem 1.3rem;
      font-size: 0.28rem;
      font-weight: 600;
      color: rgba(0, 0, 0, 1);
      text-align: center;
      border-radius: 48px;
      background: rgba(236, 237, 242, 1);
    }
    .sure {
      background: rgba(67, 110, 255, 1);
      color: rgba(255, 255, 255, 1);
    }
  }
}
</style>
